import React from 'react';
import ModalShowreel from "../components/luisvaz/modal-showreel";


class RootIndex extends React.Component {

	constructor(props) {

		super(props);
		this.state = {
			modalVisible: true,
			loaded: false
		};
		this.setModalVisible = this.updateStateModal.bind(this);
		this.containerRef = React.createRef();
	}

	updateStateModal = (state) => {
		this.setState({ modalVisible: state })
	};

	componentDidMount() {
		this.setState({ loaded: true })
	}



	render() {
		return (
			<ModalShowreel visible={this.state.modalVisible} close={this.updateStateModal.bind(null, false)} />
		)
	}
}

export default RootIndex



