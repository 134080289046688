import React, { Component } from "react";
import { gsap } from "gsap/all";
import PropTypes from 'prop-types';
import { Link } from "gatsby"
import play from "../../images/play.svg"
import logoWhite from "../../images/logo-white.svg"
import pause from "../../images/pause.svg"
import { navigate } from "gatsby"

import showreelCover from "../../images/Showeel-Cover.jpg"

class ModalShowreel extends Component {

	constructor(props) {
		super(props);
		this.modalWrap = null;
		this.modalDialog = null;
		this.modalTween = new gsap.timeline({ paused: true });
		this.keyDownHandler = this.keyDownHandler.bind(this);
		this.playPause = this.playPause.bind(this);
		this.progressBar = this.progressLoop.bind(this);
		this.play = null;
		this.video = null;
		this.wrapVideo = null;
		this.progress = null;
		this.timer = null;
		this.counter = null;
	}

	playPause() {
		if (this.video.paused) {
			this.video.play();
			this.play.classList.add("playing");
			this.play.classList.remove("pause");
			this.counter = setInterval(this.progressBar, 1000);
		} else {
			this.video.pause();
			this.play.classList.add("pause");
			this.play.classList.remove("playing");
			clearInterval(this.counter);
		}
	}

	progressLoop() {
		if (this.video) {
			this.progress.value = Math.round((this.video.currentTime / this.video.duration) * 100);
			this.timer.innerHTML = Math.round(this.video.currentTime) + " seconds";
		}
	}

	keyDownHandler(e) {
		 if (e.keyCode == 27) {
			navigate("/")
		 }
	}

	componentDidMount() {
		this.modalTween
			.to(this.modalWrap, 0.01, { autoAlpha: 1 })
			.to(this.modalDialog, 0.25, { autoAlpha: 1 }, 0)
			.reversed(true)
			.paused(false);
	}


	componentDidUpdate() {

		if (this.props.visible) {
			if (typeof window !== "undefined") {
				this.video.src = "https://jonidores.com/showreel/luisvaz.mp4";
				window.addEventListener('keydown', this.keyDownHandler);
				this.wrapVideo.addEventListener("click", this.playPause);
			}

		} else {
			this.video.load();
			if (typeof window !== "undefined") {
				window.removeEventListener('keydown', this.keyDownHandler);
			}
			this.progress.value = 0;
			this.timer.innerHTML = "0 seconds";
			this.play.classList.remove("pause");
		}
		this.modalTween.reversed(!this.props.visible);
	}

	render() {
		return <div id="modal" className="modal-overlay" ref={div => this.modalWrap = div}>
			<div className="modal-content max-width p-relative" ref={div => this.modalDialog = div}
				onClick={event => event.stopPropagation()}>
				<div className="modal-header">
					<div className="logo-modal--wraper">
						<img className="logo-modal" src={logoWhite} alt="Luis Vaz logo" />
						<div className="left-designer white">
							designer
						</div>
					</div>

					<Link to="/" id="closeBtn" className="close-modal" >
						<svg id="close-modal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73.4" xmlSpace="preserve">
							<path d="M30 30.4V0h13v30.4h30V43H43v30.4H30V43H0V30.4h30z" style={{ fill: '#ff664b' }} />
						</svg>
					</Link>
				</div>
				<div className="modal-body">
					<figure>
						<div id="wrapVideo" className="wrap-video" ref={div => this.wrapVideo = div}>
							<video id="video" poster={showreelCover} controls ref={video => this.video = video} />
							<button id="play" aria-label="Play" role="button" className="play play--hover" ref={div => this.play = div} >
								<div className="wrap-play" >
									<img className="play-img" src={play} alt="play-showreel" />
									<img className="pause-img" src={pause} alt="pause-showreel" />
								</div>
							</button>
						</div>
						<figcaption style={{ display: "flex" }}>
							<label className="timer" ref={div => this.timer = div} htmlFor="progress" role="timer" />
							<progress ref={div => this.progress = div} className="progress" max={100} value={0}>Progress</progress>
						</figcaption>
					</figure>
				</div>
				<div className="modal-footer">
					<a className="showreel__email" aria-label="Email" href="mailto:helloluisvaz@gmail.com">helloluisvaz@gmail.com</a>
					<a className="showreel__site" aria-label="Website" href="https://www.luisvaz.com/">www.luisvaz.com</a>
				</div>
			</div>
		</div>
	}

}

ModalShowreel.propTypes = {
	close: PropTypes.func,
	visible: PropTypes.bool
};

export default ModalShowreel;
